@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals/mixins";

.notfound {
  @include width2;
  padding: 7rem 2rem;
  min-height: 70vh;

  @include b.breakpoint("500") {
    padding: 5rem 2rem;
    min-height: 60vh;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
      font-size: 60px;
    }
    p {
      text-align: center;
      font-size: 20px;
      @include b.breakpoint("500") {
        font-size: 17px;
      }
    }
  }

  .backto {
    @include button;
    text-decoration: none;
  }
}
