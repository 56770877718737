@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals/mixins";

.produits {
  //   padding: 7rem 0;
  @include width3;
  padding: 7rem 0;

  @include b.breakpoint("1340") {
    padding: 3.5rem 3rem;
  }
  @include b.breakpoint("500") {
    padding: 3.5rem 1.5rem;
  }

  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}
