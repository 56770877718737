@use "../globals/breakpoints" as b;
@import "./variables";

@mixin width {
  max-width: 1340px;
  margin: 0 auto;
}
@mixin width2 {
  max-width: 1240px;
  margin: 0 auto;
}
@mixin width3 {
  max-width: 740px;
  margin: 0 auto;
}

@mixin middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin button {
  background-color: $primary;
  color: white;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: 17px;

  &:hover {
    opacity: 0.8 !important;
  }
  @include b.breakpoint("1000") {
    font-size: 15px;
    padding: 0.6rem 0.9rem;
  }
}
@mixin button2 {
  background-color: transparent;
  color: black;
  border: none;
  border-bottom: 2px solid $primary;
  cursor: pointer;
  font-size: 17px;
}

@mixin line {
  width: 50px;
  height: 5px;
  background-color: $primary;
  border-radius: 100px;
  margin: 0;
  padding: 0;
}
