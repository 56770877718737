@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals/mixins";

.app-heading {
  @include width2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 0 0;

  @include b.breakpoint("500") {
    padding: 3rem 0 0 0;
  }
}

.apps-container {
  @include width2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  padding: 5rem 0 17rem;

  @include b.breakpoint("1340") {
    gap: 1.5rem;
    padding: 3.5rem 3rem 12rem;
  }
  @include b.breakpoint("1100") {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 20rem;
    gap: 3rem;
  }
  @include b.breakpoint("500") {
    padding: 3.5rem 1.5rem 20rem;
    gap: 2rem;
  }

  a {
    text-decoration: none;
  }

  .app {
    padding: 2rem;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 0 10px 2px $primary;
    cursor: pointer;
    color: black;
    transition: all 300ms ease;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @include b.breakpoint("500") {
      padding: 1.5rem 1rem;
      gap: 0.7rem;
    }

    &:hover {
      background-color: $primary;
      color: white;
    }

    .icon {
      font-size: 40px;
      @include b.breakpoint("500") {
        font-size: 35px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 600;

      @include b.breakpoint("500") {
        font-size: 15px;
      }
    }
  }
}
