@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals/mixins";

.contact-container {
  @include width2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  padding: 7rem 0 17rem;

  @include b.breakpoint("1340") {
    gap: 1.5rem;
    padding: 3.5rem 3rem 12rem;
  }
  @include b.breakpoint("1100") {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding-bottom: 20rem;
    gap: 3rem;
  }
  @include b.breakpoint("500") {
    padding: 3.5rem 1.5rem 20rem;
  }

  .infomations {
    width: 80%;

    @include b.breakpoint("700") {
      width: 100%;
    }

    .head {
      .line2 {
        @include line;
        margin-bottom: 2rem;
        margin-top: -1.4rem;

        @include b.breakpoint("1000") {
          margin-bottom: 1rem;
          margin-top: -0.5rem;
        }
      }

      h2 {
        font-size: 35px;
        line-height: 1.5;
        font-weight: 600;
        margin-bottom: 2rem;

        @include b.breakpoint("1000") {
          grid-template-columns: 1fr;
          margin-bottom: 1rem;
          font-size: 25px;
        }
      }
      h3 {
        font-size: 20px;
        line-height: 2;
        font-weight: 500;
        margin-bottom: 2rem;
        display: block;

        @include b.breakpoint("1000") {
          margin-bottom: 1rem;
          font-size: 17px;
        }
      }
    }

    .actual-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .item {
        padding: 0;
        margin: 0;

        &:hover {
          opacity: 0.7;
        }

        a {
          line-height: 2;
          display: flex;
          flex-direction: row;
          gap: 1rem;
          align-items: center;
          font-size: 27px;
          color: $primary;
          text-decoration: none;
          // text-transform: capitalize;

          @include b.breakpoint("1000") {
            font-size: 20px;
          }
          p {
            font-size: 20px;
            color: black;
            text-transform: none;

            @include b.breakpoint("1000") {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

.contact-form {
  position: relative;

  form {
    width: 500px;
    height: 650px;
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    @include b.breakpoint("1000") {
      padding: 1.5rem;
      font-size: 17px;
    }
    @include b.breakpoint("700") {
      width: 100%;
    }

    .thecaptcha {
      margin-top: 2rem;
    }

    .item {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      label {
        font-size: 17px;
        color: black;

        @include b.breakpoint("1000") {
          font-size: 15px;
        }
      }
      .input-item {
        width: 100%;
        height: 100%;
        border: 2px solid #48a73d6f;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 27px;
        align-items: center;
        padding: 0.5rem 1rem;
        color: $primary;

        @include b.breakpoint("1000") {
          font-size: 17px;
        }

        input,
        textarea {
          width: 100%;
          height: 100%;
          font-size: 17px;
          color: black;
          font-weight: 600;
          padding: 0 0 0 0.5rem;
          background-color: transparent;
          border: none;
          outline: none;

          @include b.breakpoint("1000") {
            font-size: 15px;
          }
        }
        textarea {
          resize: none;
        }
      }
    }

    button {
      align-self: flex-end;
      margin-top: 1rem;

      @include button;
    }
  }

  // anim
  .image {
    width: 500px;
    height: 650px;
    background-color: $primary;
    position: absolute;
    top: 0.8rem;
    border-radius: 15px;
    left: 0.8rem;
    z-index: 1;

    @include b.breakpoint("700") {
      top: 0.5rem;
      left: 0.5rem;
      width: 100%;
      // height: 400px;
    }
  }
}

/*  notifications */
.notification {
  background-color: white;
  color: black;
  position: fixed;
  top: 3%;
  right: 2%;
  padding: 1rem 1rem 0.7rem 1rem;
  border-radius: 10px 10px 0 10px;
  border-bottom: 0.3rem solid $primary;
  z-index: 20000;
  box-shadow: 0 0 12px 12px rgba(20, 20, 20, 0.09);
}
