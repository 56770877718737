@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals/mixins";

.sectionC {
  @include width2;
  padding: 7rem 0 2rem 0;
  position: relative;

  @include b.breakpoint("1340") {
    padding: 3.5rem 3rem;
  }
  @include b.breakpoint("500") {
    padding: 3.5rem 1.5rem 0rem 1.5rem;
  }

  .head {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
      font-size: 17px;
      line-height: 2;
      font-weight: 500;
    }

    .line {
      @include line;
      margin-bottom: 2rem;

      @include b.breakpoint("1000") {
        margin-bottom: 1rem;
      }
    }

    h2 {
      font-size: 35px;
      line-height: 1.5;
      font-weight: 600;
      width: 80%;
      text-align: center;
      margin-bottom: 2rem;

      @include b.breakpoint("1000") {
        grid-template-columns: 1fr;
        margin-bottom: 1rem;
        font-size: 25px;
        width: 100%;
      }
    }
    h3 {
      font-size: 20px;
      line-height: 1.5;
      font-weight: 500;
      margin-bottom: 2rem;
      display: block;
      width: 60%;
      text-align: center;

      @include b.breakpoint("1000") {
        margin-bottom: 1rem;
        font-size: 17px;
        width: 100%;
      }
    }
  }

  .elements {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 70%;
    margin: 0 auto;

    @include b.breakpoint("700") {
      grid-template-columns: 1fr;
      gap: 1rem;
      width: 100%;
    }

    .elm {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include b.breakpoint("700") {
        align-items: center;
        justify-content: center;
        gap: 1rem;
      }

      div {
        min-height: 80px;
        @include b.breakpoint("700") {
          text-align: center;
          min-height: 70px;
        }
        p {
          font-size: 18px;
          line-height: 1.5;
          font-weight: 500;
          display: block;
          width: 90%;

          @include b.breakpoint("700") {
            width: 100%;
          }
        }
      }
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
    button {
      @include button2;
      font-weight: 600;
    }
  }
}
