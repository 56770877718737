@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals/mixins";

.sectionB {
  background-color: #9fc7aa79;
  padding: 7rem 0;
  border-radius: 15px;
  position: relative;

  @include b.breakpoint("1340") {
    padding: 3.5rem 3rem;
  }
  @include b.breakpoint("500") {
    padding: 3.5rem 1.5rem;
  }

  .width-seter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    @include width2;

    @include b.breakpoint("1340") {
      gap: 1.5rem;
    }
    @include b.breakpoint("1000") {
      grid-template-columns: 1fr;
      gap: 4rem;
    }
    @include b.breakpoint("700") {
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    .texts {
      display: flex;
      flex-direction: column;
      width: 90%;
      @include b.breakpoint("1340") {
        width: 100%;
      }
      @include b.breakpoint("1000") {
        grid-row: 1;
      }

      h4 {
        font-size: 17px;
        line-height: 2;
        font-weight: 500;
      }

      .line {
        @include line;
        margin-bottom: 2rem;

        @include b.breakpoint("1000") {
          margin-bottom: 1rem;
        }
      }

      .line2 {
        @include line;
        margin-bottom: 2rem;
        margin-top: -1.4rem;

        @include b.breakpoint("1000") {
          margin-bottom: 1rem;
          margin-top: -0.5rem;
        }
      }

      h2 {
        font-size: 35px;
        line-height: 1.5;
        font-weight: 600;
        margin-bottom: 2rem;

        @include b.breakpoint("1000") {
          grid-template-columns: 1fr;
          margin-bottom: 1rem;
          font-size: 25px;
        }
      }
      h3 {
        font-size: 20px;
        line-height: 2;
        font-weight: 500;
        margin-bottom: 2rem;
        display: block;

        @include b.breakpoint("1000") {
          margin-bottom: 1rem;
          font-size: 17px;
        }
      }
      div {
        padding: 0;
        margin: 0;
        button {
          @include button;
          margin-bottom: 2rem;
        }
      }
    }

    .illustration {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      @include b.breakpoint("1000") {
        justify-content: flex-start;
      }
      @include b.breakpoint("700") {
        padding-right: 0.5rem;
      }

      img {
        &:first-child {
          border-radius: 10px;
          width: 500px;
          height: 550px;
          object-fit: cover;
          z-index: 2;
          @include b.breakpoint("1000") {
            width: 400px;
            height: 450px;
          }
          @include b.breakpoint("700") {
            width: 100%;
            height: 400px;
          }
        }
      }
      .image {
        width: 500px;
        height: 550px;
        background-color: $primary;
        position: absolute;
        top: 0.8rem;
        border-radius: 15px;
        left: 2.2rem;
        z-index: 1;
        @include b.breakpoint("1000") {
          width: 400px;
          height: 450px;
          top: 0.7rem;
          left: 0.7rem;
        }
        @include b.breakpoint("700") {
          top: 0.5rem;
          left: 0rem;
          width: 100%;
          height: 400px;
        }
      }
    }
  }

  .layer1 {
    width: 150px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    @include b.breakpoint("700") {
      display: none;
    }
  }

  .layer2 {
    width: 150px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);

    @include b.breakpoint("700") {
      display: none;
    }
  }

  .layer3 {
    width: 150px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);

    @include b.breakpoint("700") {
      display: none;
    }
  }
}

.sectionAt {
  grid-row: 1;
}
.as {
  @include b.breakpoint("1000") {
    grid-row: 2 !important;
  }
}
