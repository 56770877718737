@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals//mixins";

.small-hero {
  width: 100vw;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  border-radius: 0 0 15px 15px;
  overflow: hidden;

  @include b.breakpoint("700") {
    height: 200px;
    background-position: bottom;
  }

  .shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 82, 3, 0.399);
  }

  .elm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    z-index: 2;

    @include b.breakpoint("700") {
      width: 90%;
      text-align: center;
    }
  }

  h2 {
    color: white;
    font-size: 50px;
    text-align: center;

    @include b.breakpoint("700") {
      font-size: 30px;
      line-height: 1;
      width: 100%;
    }
  }
  h3 {
    font-size: 17px;
    line-height: 2;
    font-weight: 500;
    display: block;
    color: rgba(255, 255, 255, 0.566);
    text-align: center;

    @include b.breakpoint("1000") {
      margin-top: 1rem;
      font-size: 15px;
      line-height: 1.7;
    }
  }
}
