@use "../globals/breakpoints" as b;
@import "../globals/variables";

.back-to-top {
  position: fixed;
  z-index: 3;
  bottom: 2rem;
  right: 2rem;
  padding: 0.5rem;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: $primary;
  border: 2px solid $primary;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @include b.breakpoint("700") {
    bottom: 1rem;
    right: 1rem;
    font-size: 22px;
    border: 1px solid $primary;
  }
}
