@use "../globals/breakpoints" as b;
@import "../globals/variables";
@import "../globals/mixins";

.single-product {
	.points {
		margin: 0 0 2rem 0;
		ul {
			display: flex;
			flex-direction: column;
			gap: 0.7rem;

			li {
				list-style: none;
				font-size: 17px;
				color: black;
				text-transform: capitalize;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 0.5rem;

				@include b.breakpoint("1000") {
					font-size: 16px;
				}
				@include b.breakpoint("700") {
					font-size: 14px;
				}

				.icons {
					fill: $primary;
				}
			}
		}
	}

	.tags {
		ul {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			li {
				list-style: none;
				font-size: 17px;
				background-color: #c3d6c9;
				padding: 0.5rem 0 0.5rem 1rem;
				border-radius: 10px;
				color: black;
				text-transform: capitalize;

				@include b.breakpoint("1000") {
					font-size: 16px;
				}
				@include b.breakpoint("700") {
					font-size: 14px;
				}
			}
		}
	}

	.product-button {
		padding: 0;
		margin: 0;
		button {
			@include button;
			margin-top: 2rem;
		}
	}

	//   style
	background-color: white;
	padding: 2rem 2rem 3rem;
	border-radius: 20px;

	@include b.breakpoint("1000") {
		padding: 1rem 1rem 2rem;
	}
	@include b.breakpoint("700") {
		padding: 1.5rem 1.5rem 2rem;
	}
}

.product-message {
	display: flex;
	flex-direction: column;
	padding-top: 2rem;

	@include b.breakpoint("1340") {
		width: 100%;
	}

	h4 {
		font-size: 17px;
		line-height: 2;
		font-weight: 500;
	}

	.line {
		@include line;
		margin-bottom: 2rem;

		@include b.breakpoint("1000") {
			margin-bottom: 1rem;
		}
	}

	h2 {
		font-size: 25px;
		line-height: 1;
		font-weight: 600;
		

		@include b.breakpoint("1000") {
			grid-template-columns: 1fr;
			margin-bottom: 1rem;
			font-size: 25px;
		}
	}
	h3 {
		font-size: 20px;
		line-height: 2;
		font-weight: 500;
		margin-bottom: 2rem;
		display: block;

		@include b.breakpoint("1000") {
			margin-bottom: 1rem;
			font-size: 17px;
		}
	}
	div {
		padding: 0;
		margin: 0;
		button {
			@include button;
			margin-bottom: 2rem;
		}
	}
}